@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&display=swap');
body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.5;
  font-size: 15px;
}

h1, h2, h3, h4 {
  font-weight: 800;
}

h2 {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.screen-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}


.content-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  padding: 1rem 0;
}

.flex {

  display: flex;

  &--justify-center {
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }
}

p {
  color: #969696;
}


.text {

  &--center {
    text-align: center;
  }
}

.padding {

  &-bottom{
    &--large {
      padding-bottom: 24px;
    }

    &--medium {
      padding-bottom: 12px;
    }
  }

  &-left {
    &--large {
      padding-left: 24px;
    }
  }

  &-top {
    &--large {
      padding-top: 24px;
    }

    &--medium {
      padding-top: 12px;
    }
  }

  &-right {
    &--large {
      padding-left: 24px;
    }
  }
}

.margin {

  &-bottom{
    &--large {
      padding-bottom: 24px;
    }

    &--medium {
      padding-bottom: 12px;
    }
  }

  &-left {
    &--large {
      padding-left: 24px;
    }
  }

  &-right {
    &--large {
      padding-left: 24px;
    }
  }
}

.logo {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__img{
    max-height: 40px;
  }
}

ol{
  padding-left: 18px;

  &.mobile-list-item {
   padding-left: 24px;
  }

  li {

  }
}

.store-logo {

  &__container {
    margin-left: -20px;
    display: flex;
    justify-content: space-around;
  }

}

.button {

  background-color: #063378;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: auto;
  max-width: 320px;
  padding: 13px 16px;
  text-align: center;
  text-decoration: none;

}
